import { User } from 'fitify-types/src/types/user'
import { useUserContext } from 'fitify-ui-onboarding/src/contexts/UserContext'
import { useRouter } from 'fitify-ui-onboarding/src/hooks/useRouter'
import { useEffect } from 'react'

import {
  APP_PAGES,
  APP_PAGES_MAP,
  ONBOARDING_PAGES,
  ONBOARDING_PAGES_MAP,
} from 'utils/routes'

const DISABLE_APP_PAGES = APP_PAGES_MAP.map((page) => page.path)
const DISABLED_PAGES = [
  ONBOARDING_PAGES.home.path,
  ONBOARDING_PAGES.success.path,
  ...DISABLE_APP_PAGES,
]

export function useUserRedirect(userProfile: User | null) {
  const router = useRouter()
  const { isAuthLoading } = useUserContext()

  useEffect(() => {
    const handleRedirect = () => {
      if (isAuthLoading || !userProfile) return
      const { onboarding_completed, subscription } = userProfile
      const routes = ONBOARDING_PAGES_MAP
      const stepPages = routes
        ?.filter((route) => route.key)
        .map((route) => route.path)

      const hasActiveSubscription = subscription?.active
      const hasCompletedOnboarding = onboarding_completed?.workouts

      if (hasActiveSubscription && router.pathname !== APP_PAGES.gift.path) {
        router.replace(ONBOARDING_PAGES.success.path)
      } else {
        if (!stepPages?.includes(router.pathname)) return

        if (hasCompletedOnboarding) {
          router.replace(ONBOARDING_PAGES.subscription.path)
        }
      }
    }

    if (!DISABLED_PAGES.includes(router.pathname)) {
      handleRedirect()
    }
  }, [isAuthLoading, router, userProfile])
}
