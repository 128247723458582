import React, { ChangeEvent, forwardRef, KeyboardEvent } from 'react'

import { FormLabel, StyledFormInput } from '../Form'

export type NumberInputProps = {
  autoFocus?: boolean
  invalid?: boolean
  name: string
  placeholder: string
  value: string | number | undefined
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (
    event: KeyboardEvent<HTMLInputElement>,
    isNumeric: boolean
  ) => void
  onWheel?: (event: React.WheelEvent<HTMLInputElement>) => void
  suffix?: string
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      autoFocus,
      invalid,
      name,
      onChange,
      onKeyDown,
      onWheel,
      placeholder,
      suffix,
      value,
    },
    ref
  ) => {
    return (
      <FormLabel $invalid={invalid}>
        {suffix && <legend>{suffix}</legend>}
        <StyledFormInput
          autoFocus={autoFocus}
          type="number"
          name={name}
          placeholder={placeholder}
          pattern="\d*"
          onChange={onChange}
          onKeyDown={(e) => onKeyDown(e, true)}
          onWheel={onWheel ? onWheel : (e) => e.currentTarget.blur()}
          value={value}
          ref={ref}
        />
      </FormLabel>
    )
  }
)
