import { styled, css } from 'styled-components'

const defaultLabelPlaceholder = css`
  top: 30%;
  font-size: 12px;
  font-weight: 600;
  transform: translateY(-50%) scale(0.9);
`

export const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const StyledInput = styled.input<{
  $invalid?: boolean
  $hasValue: boolean
}>`
  width: 100%;
  padding: 22px 12px 10px;
  background-color: transparent !important;

  border-radius: 12px;
  font-size: 16px;
  font-weight: ${(props) => (props.theme.digital ? 500 : 600)};
  outline: none;
  transition: 0.1s ease-out;

  font-family: var(--f-text);

  border: 1px solid var(--c-animated-input-border);
  color: var(--c-animated-input-color);

  &:focus-within {
    border-color: var(--c-animated-input-border-focus);
    & ~ label {
      ${defaultLabelPlaceholder}
    }
  }

  /* Removes autocomplete backgound */
  &:is(:-webkit-autofill, :autofill) {
    background-clip: text;
    -webkit-text-fill-color: var(--c-animated-input-autofill) !important;
    & ~ label {
      ${defaultLabelPlaceholder}
    }
  }

  ${(props) => {
    if (props.$hasValue) {
      return css`
        & ~ label {
          ${defaultLabelPlaceholder}
        }
      `
    }

    return null
  }}

  ${(props) => {
    if (props.$invalid) {
      return css`
        border-color: var(--c-red);
      `
    }

    return null
  }}
`

export const StyledLabel = styled.label`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin-left: 12px;

  font-family: var(--f-text);
  font-size: 16px;
  font-weight: 500;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;

  color: var(--c-animated-input-label-color);
`
