import {
  ContentContainer,
  HeadingContainer,
  PageContainer,
  PitchImage,
  PitchText,
  StyledPrimaryButton,
  Threshold,
} from 'fitify-ui-onboarding/src/components'
import { Heading } from 'fitify-ui-onboarding/src/components/Typography'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

const PitchHeading = styled(Heading)`
  font-weight: 700;
  text-align: center;
  b {
    color: var(--c-vibrant-green);
  }
`

export const Pitch = ({
  t,
  data: { title, image, messages, hasImageShadow },
  actions: { handleContinue },
  children,
}: {
  t: (key: string) => string
  data: {
    title: string
    image?: {
      alt: string
      src: string
      srcSet: string
      width: number
      height: number
      suffix?: ReactNode
    }
    hasImageShadow?: boolean
    messages?: string[]
  }
  actions: {
    handleContinue: () => void
  }
  children?: React.ReactNode
}) => {
  return (
    <PageContainer>
      <HeadingContainer>
        <PitchHeading dangerouslySetInnerHTML={{ __html: t(title) }} />
      </HeadingContainer>

      <ContentContainer xs="1.5rem">
        {image && <PitchImage {...image} hasImageShadow={hasImageShadow} />}
        {children && children}

        {messages?.map((message, index) => (
          <PitchText
            key={index}
            dangerouslySetInnerHTML={{ __html: t(message) }}
          />
        ))}
        <Threshold hasBackground>
          <StyledPrimaryButton onClick={handleContinue}>
            {t('btn_continue')}
          </StyledPrimaryButton>
        </Threshold>
      </ContentContainer>
    </PageContainer>
  )
}
