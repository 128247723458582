import { ReactNode } from 'react'

import {
  StyledSegmentControl,
  StyledSegmentControlItem,
} from './SegmentControl.Styled'

export type SegmentControlOption<TValue extends number | string> = {
  title: ReactNode
  value: TValue
}

export type SegmentControlProps<TValue extends number | string> = {
  onSelectedChange: (value: TValue) => void
  options: SegmentControlOption<TValue>[]
  selected: TValue
}

export const SegmentControl = <TValue extends number | string>({
  onSelectedChange,
  options,
  selected,
}: SegmentControlProps<TValue>) => {
  return (
    <StyledSegmentControl>
      {options.map((option) => (
        <StyledSegmentControlItem
          key={option.value}
          selected={selected === option.value}
          onClick={() => onSelectedChange(option.value)}
        >
          {option.title}
        </StyledSegmentControlItem>
      ))}
    </StyledSegmentControl>
  )
}
