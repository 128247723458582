import { styled, css } from 'styled-components'

export const StyledBadge = styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 8px;
  padding: 0.3em 0.6em;
  min-width: max-content;

  ${(props) => {
    switch (true) {
      case props.theme.digital:
        return css`
          position: absolute;
          top: -26px;
          left: 50%;
          right: 50%;
          transform: translate(-50%);
          color: var(--c-white);
          background: var(--c-pink400);
        `
      default:
        return null
    }
  }}
`
