import { InputHTMLAttributes } from 'react'

import { FormLabel, StyledFormInput } from '../Form'

type Props = {
  // eslint-disable-next-line react/boolean-prop-naming
  invalid?: boolean
} & InputHTMLAttributes<HTMLInputElement>

export const Input = ({
  invalid,
  type = 'text',
  value,
  ...htmlProps
}: Props) => {
  return (
    <FormLabel $invalid={invalid}>
      <StyledFormInput {...htmlProps} type={type} value={value ?? ''} />
    </FormLabel>
  )
}
