export class PromiseCancelledError extends Error {
  constructor() {
    super('Promise cancelled')
    this.name = 'PromiseCancelledError'
  }
}

export const isPromiseCancelledError = (error: unknown): boolean => {
  return error instanceof PromiseCancelledError
}

/**
 * Wraps a promise in a new promise that can be cancelled by an AbortSignal.
 *
 * Explanation:
 *
 *  1.	AbortController and AbortSignal: We use AbortController to create
 *      a AbortSignal that can be used to track the cancellation state.
 *  2.	Cancellable Promise Wrapper: We create a new promise that listens
 *      to the signal’s abort event. When abort() is called, the promise rejects
 *      with an PromiseCancelledError.
 *  3.	Cancellation: If the promise is cancelled, abort() will trigger
 *      an immediate rejection with PromiseCancelledError.
 *
 * Example usage in react component:
 *
 * ```ts
 * const promiseController = useRef<AbortController | undefined>(undefined)
 *
 * const someAsyncFunction = async() => {
 *   try {
 *     promiseController.current?.abort()
 *     promiseController.current = new AbortController()
 *
 *     // Do something before call cancellable promise
 *
 *     const result = await cancellablePromise(
 *       asyncFunctionThatMayBeCancelled(),
 *       promiseController.current.signal
 *     )
 *
 *     // Do something with result
 *   } catch (error) {
 *     if(!isPromiseCancelledError(error)) {
 *       // Do something with other than promise cancelled error
 *     } else {
 *       // Do something if promise was cancelled
 *     }
 *   }
 * }
 * ```
 */
export const cancellablePromise = <T>(
  promise: Promise<T>,
  signal: AbortSignal
): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    signal.addEventListener('abort', () => {
      reject(new PromiseCancelledError())
    })

    if (signal.aborted) {
      reject(signal.reason)
    }

    promise
      .then((result) => {
        if (!signal.aborted) {
          resolve(result)
        }
      })
      .catch((error) => {
        if (!signal.aborted) {
          reject(error)
        }
      })
  })
}
