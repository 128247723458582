import { Text } from 'fitify-ui-onboarding/src/components'
import { HTMLAttributes, ReactNode } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { styled } from 'styled-components'

const StyledFooter = styled.footer`
  text-align: center;
  padding: 1rem;

  p,
  a {
    color: ${(props) => {
      switch (true) {
        case props.theme.hcV1:
          return 'var(--c-gray600)'
        case props.theme.digital:
        default:
          return 'var(--c-blue75)'
      }
    }};
  }

  a {
    transition:
      color 0.3s,
      opacity 0.3s;

    &:hover {
      opacity: 0.75;
    }
  }
`

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  disclamer: ReactNode
}

export const Footer = ({ disclamer }: FooterProps) => {
  const isPhone = isAndroid || isIOS

  return (
    <StyledFooter>
      <Text $size="xs">
        &copy; Fitify Workouts s.r.o., {new Date().getFullYear()}
        {!isPhone && (
          <>
            <br />
            {disclamer}
          </>
        )}
      </Text>
    </StyledFooter>
  )
}
