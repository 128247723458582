import dynamic from 'next/dynamic'
import { CSSProperties, useContext } from 'react'
import { ThemeContext } from 'styled-components'

const ThresholdWrapper = dynamic(() => import('./ThresholdWrapper'))
const ThresholdWrapperV2 = dynamic(() => import('./ThresholdWrapperV2'))

export type ThresholdProps = {
  hasBackground?: boolean
  children: JSX.Element
  style?: CSSProperties
}

export const Threshold = ({
  hasBackground,
  children,
  style,
}: ThresholdProps) => {
  const theme = useContext(ThemeContext)

  return theme?.digital ? (
    <ThresholdWrapper hasBackground={hasBackground}>
      {children}
    </ThresholdWrapper>
  ) : (
    <ThresholdWrapperV2 hasBackground={hasBackground} style={style}>
      {children}
    </ThresholdWrapperV2>
  )
}
