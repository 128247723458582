import { Url } from 'next/dist/shared/lib/router/router'
import { useRouter as useNextRouter, NextRouter } from 'next/router'
import { useMemo } from 'react'

import { encodeQuery } from '../utils/query'

import { usePromo } from './usePromo'

export const useRouter = () => {
  const router = useNextRouter()
  const promoCode = usePromo()

  return useMemo(() => {
    const addPromoToUrl = (url: Url) => {
      if (typeof url === 'string') {
        const [path, queryString] = url.toString().split('?')
        const query = Object.fromEntries(new URLSearchParams(queryString || ''))
        return encodeQuery(path, { ...query, promo: promoCode })
      } else {
        let newQuery

        if (url.query) {
          if (typeof url.query === 'string') {
            newQuery = `${url.query}&promo=${promoCode}`
          } else {
            newQuery = { ...url.query, promo: promoCode }
          }
        } else {
          newQuery = { promo: promoCode }
        }
        return { ...url, query: newQuery }
      }
    }

    const push: NextRouter['push'] = (url, as, options) => {
      if (promoCode) {
        return router.push(addPromoToUrl(url), as && addPromoToUrl(as), options)
      }
      return router.push(url, as, options)
    }

    const replace: NextRouter['replace'] = (url, as, options) => {
      if (promoCode) {
        return router.replace(
          addPromoToUrl(url),
          as && addPromoToUrl(as),
          options
        )
      }
      return router.replace(url, as, options)
    }

    return {
      ...router,
      push,
      replace,
    }
  }, [promoCode, router])
}
