import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'
import 'theme/Fonts.css'

import { getAnalytics, setUserProperties } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getPerformance } from 'firebase/performance'
import {
  RemoteConfig,
  useRemoteConfig,
} from 'fitify-ui/src/hooks/useRemoteConfig'
import { useSmartlook } from 'fitify-ui/src/hooks/useSmartlook'
import { logEvent } from 'fitify-ui/src/utils/analytics'
import { Toast } from 'fitify-ui-onboarding/src/components'
import ErrorBoundary from 'fitify-ui-onboarding/src/components/Error/ErrorBoundary'
import { UserContextProvider } from 'fitify-ui-onboarding/src/contexts/UserContext'
import { GlobalStyles } from 'fitify-ui-onboarding/src/theme/Global'
import { type Theme } from 'fitify-ui-onboarding/src/types/theme'
import {
  CacheContext,
  ICacheData,
  ICacheValue,
} from 'fitify-ui-onboarding/src/utils/cache'
import { toBoolean } from 'fitify-utils/src/properties'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import { useEffect, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'
import { SkeletonTheme } from 'react-loading-skeleton'
import StackdriverErrorReporter from 'stackdriver-errors-js'
import { ThemeProvider } from 'styled-components'

import { PlanApp } from 'sections/plan/PlanApp'

import remoteConfigDefaults from '../config/remoteConfigDefaults.json'

declare global {
  interface Window {
    // Used in VWO
    updateOptimizeConfig: (optimizeConfig: Record<string, unknown>) => void
  }
}

const app = initializeApp({
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
})

// Connects to functions emulator
if (process.env.NEXT_PUBLIC_FUNCTIONS_EMULATOR_PORT) {
  connectFunctionsEmulator(
    getFunctions(),
    'localhost',
    parseInt(process.env.NEXT_PUBLIC_FUNCTIONS_EMULATOR_PORT)
  )
}

// Connects to auth emulator
if (process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_URL) {
  const auth = getAuth()
  connectAuthEmulator(auth, process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_URL)
}

// Connects to store emulator
if (process.env.NEXT_PUBLIC_FIREBASE_STORE_EMULATOR_PORT) {
  const db = getFirestore()
  const port = parseInt(process.env.NEXT_PUBLIC_FIREBASE_STORE_EMULATOR_PORT)

  connectFirestoreEmulator(db, 'localhost', port)
}

// Theme
const theme: Theme = {
  digital: true,
}

function FitifyApp({ Component, pageProps }: AppProps) {
  const ERROR_BOUNDARY_VERSION = '1'
  const ERROR_BOUNDARY_SERVICE = 'fitify-onboarding'

  const remoteConfig = useRemoteConfig(remoteConfigDefaults)
  const [cacheData, setCacheData] = useState<ICacheData>({})
  const [optimizeConfig, setOptimizeConfig] = useState<RemoteConfig>()
  const firebaseAuth = getAuth()

  const setCacheKey = (key: string, value: ICacheValue) => {
    setCacheData((cache) => ({ ...cache, [key]: value }))
  }

  const config: RemoteConfig = useMemo(() => {
    return { ...remoteConfig, ...optimizeConfig }
  }, [remoteConfig, optimizeConfig])

  // Handle smartlook
  const isSmartlookEnabled = toBoolean(config?.['smartlook_enabled'])
  useSmartlook(isSmartlookEnabled)

  useEffect(() => {
    window.updateOptimizeConfig = (newOptimizeConfig) => {
      const updatedOptimizeConfig = {
        ...optimizeConfig,
        ...newOptimizeConfig,
      }
      setOptimizeConfig(updatedOptimizeConfig)
    }
  }, [optimizeConfig])

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      const errorHandler = new StackdriverErrorReporter()
      errorHandler.start({
        key: process.env.NEXT_PUBLIC_API_KEY,
        projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
        service: ERROR_BOUNDARY_SERVICE,
        version: ERROR_BOUNDARY_VERSION,
      })

      window.onerror = function (msg, file, line, col, error) {
        // callback is called with an Array[StackFrame]
        if (error) {
          if (firebaseAuth.currentUser) {
            errorHandler.setUser(firebaseAuth.currentUser.uid)
          }
          errorHandler.report(error)
        }
      }

      getPerformance(app)
    }
  }, [firebaseAuth.currentUser])

  useEffect(() => {
    const analytics = getAnalytics()
    const url = new URL(window.location.href)
    const utmSource = url.searchParams.get('utm_source')
    const utmMedium = url.searchParams.get('utm_medium')
    const utmCampaign = url.searchParams.get('utm_campaign')
    const utmContent = url.searchParams.get('utm_content')
    const utmTerm = url.searchParams.get('utm_term')

    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    })

    setUserProperties(analytics, {
      source: utmSource,
      medium: utmMedium,
      campaign: utmCampaign,
    })

    if (utmSource && utmMedium && utmCampaign) {
      logEvent('utm_conversion', {
        source: utmSource,
        medium: utmMedium,
        campaign: utmCampaign,
        content: utmContent,
        term: utmTerm,
      })
    }
  }, [])

  const cacheContextProviderValue = useMemo(
    () => ({
      data: cacheData,
      set: setCacheKey,
    }),
    [cacheData]
  )

  return (
    <>
      <Head>
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <CacheContext.Provider value={cacheContextProviderValue}>
        <ErrorBoundary
          isProduction={process.env.NODE_ENV === 'production'}
          apiKey={process.env.NEXT_PUBLIC_API_KEY}
          projectId={process.env.NEXT_PUBLIC_PROJECT_ID}
          service={ERROR_BOUNDARY_SERVICE}
          version={ERROR_BOUNDARY_VERSION}
        >
          <ThemeProvider theme={theme}>
            <SkeletonTheme
              baseColor="var(--c-blue850)"
              highlightColor="#134A90"
              borderRadius="0.250rem"
              duration={1}
              enableAnimation
            >
              <GlobalStyles theme={theme} />

              <UserContextProvider>
                <PlanApp
                  Component={Component}
                  pageProps={pageProps}
                  config={config}
                />
              </UserContextProvider>
              <Toast />
            </SkeletonTheme>
          </ThemeProvider>
        </ErrorBoundary>
      </CacheContext.Provider>
    </>
  )
}

export default appWithTranslation(FitifyApp)
