import { ReactNode } from 'react'
import { styled } from 'styled-components'

import { BREAKPOINTS } from '../../utils/styled'

const StyledSalesPitchImage = styled.img`
  position: relative;
  pointer-events: none;
  cursor: default;
  border-radius: 16px;

  display: flex;
  align-items: center;

  ${(props) => props.width && `width: ${props.width}px;`}
  ${(props) => props.height && `height: ${props.height}px;`}

  @media ${BREAKPOINTS.MD.lte} {
    max-width: 100%;
    height: auto;
  }
`

const StyledSalesPitchBackground = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;

  opacity: 20%;
  filter: blur(40px);
  margin-top: 32px;
  background: var(--c-blue400);
`

const StyledPitchImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PitchImage = ({
  hasImageShadow,
  width,
  height,
  suffix,
  ...rest
}: {
  alt: string
  hasImageShadow?: boolean
  src: string
  srcSet?: string
  width?: number
  height?: number
  suffix?: ReactNode
}) => {
  return (
    <StyledPitchImageWrapper>
      {hasImageShadow ? <StyledSalesPitchBackground /> : null}
      <StyledSalesPitchImage width={width} height={height} {...rest} />
      {suffix}
    </StyledPitchImageWrapper>
  )
}
