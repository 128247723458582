import { getFunctions, httpsCallable } from 'firebase/functions'
import { UserProfile } from 'fitify-types/src/types/functions'
import { toast } from 'fitify-ui-onboarding/src/components/Toast'
import { getNavigatorLocale } from 'fitify-ui-onboarding/src/utils/language'

import { IDataState } from 'utils/state'

export const createUserProfile = async (data: IDataState): Promise<boolean> => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [language, country] = getNavigatorLocale().split('-')

  const userData: UserProfile.Create.Request = {
    ...data,
    locale: {
      timezone,
      language,
      country,
    },
    newsletter: true,
  }

  try {
    await httpsCallable<
      UserProfile.Create.Request,
      UserProfile.Create.Response
    >(
      getFunctions(),
      'createUserProfile'
    )(userData)
    return true
  } catch (err) {
    console.error(err)
    toast({
      type: 'error',
      content: 'Failed to create user profile',
    })
  }

  return false
}
