import {
  Stack,
  SubscriptionDescriptionText,
} from 'fitify-ui-onboarding/src/components'
import { getPlanPrice } from 'fitify-ui-onboarding/src/components/Plan/utils'
import {
  PlanInterval,
  IPlanPrices,
  Subscription,
  SubscriptionPlan,
} from 'fitify-ui-onboarding/src/types'
import { useTranslation } from 'next-i18next'
import { useContext, useMemo } from 'react'
import { ThemeContext } from 'styled-components'

export enum EPlanTypes {
  REGULAR = 'regular',
  TRIAL = 'trial',
  DISCOUNTED = 'discounted',
}

const PlanDetailText = ({
  activePlan,
  planType,
  price,
}: {
  activePlan?: SubscriptionPlan
  planType: EPlanTypes
  price: IPlanPrices | null
}) => {
  const { t } = useTranslation()
  const isTrial = planType === EPlanTypes.TRIAL
  const isDiscounted = planType === EPlanTypes.DISCOUNTED
  const theme = useContext(ThemeContext)

  if (isTrial && price?.discountDuration) {
    return theme?.hcV1 ? (
      <>
        <b>
          {t('hc_pro_paywall_free_trial_x_days', {
            0: price.discountDuration,
            count: price.discountDuration,
          })}
        </b>
        {'. '}
        {t('hc_pro_paywall_term_introductory_monthly_part2', {
          0: price?.original,
        })}
      </>
    ) : (
      <>
        <b>
          {t('pro_smallprint_trial_first', {
            0: price?.discountDuration || 0,
            count: price?.discountDuration || 0,
          })}
        </b>{' '}
        {t('pro_smallprint_then', {
          0: price?.original,
          1: activePlan?.interval === PlanInterval.YEAR ? 12 : 1,
          count: activePlan?.interval === PlanInterval.YEAR ? 12 : 1,
        })}
      </>
    )
  }

  if (isDiscounted && price?.discountDuration) {
    return theme?.hcV1 ? (
      <>
        <b>
          {t('hc_pro_paywall_term_introductory_monthly_part1', {
            0: price?.current,
            1: price?.discountDuration || 0,
            count: price?.discountDuration || 0,
          })}
        </b>{' '}
        {t('hc_pro_paywall_term_introductory_monthly_part2', {
          0:
            price.discountInterval && price.discountInterval > 1
              ? price?.current
              : price?.futurePriceTotalValue,
        })}
      </>
    ) : (
      <>
        <b>
          {t('pro_smallprint_introductory_offer_first', {
            0: price.total,
            1: price?.discountInterval || 0,
            count: price?.discountInterval || 0,
          })}
        </b>{' '}
        {t('pro_smallprint_then', {
          0: price?.futurePriceTotalValue || 0,
          1: price?.discountInterval || 0,
          count: price?.discountInterval || 0,
        })}
      </>
    )
  }

  return null
}

export const PlanDetail = ({
  subscription,
}: {
  subscription: Subscription
}) => {
  const activePlan = useMemo(
    () =>
      subscription?.plans?.find(
        (plan) => plan.path === subscription.activePlan
      ),
    [subscription]
  )

  const planType = activePlan?.type as EPlanTypes
  const price = useMemo(() => getPlanPrice(activePlan), [activePlan])

  return (
    <Stack>
      <SubscriptionDescriptionText>
        <PlanDetailText
          planType={planType}
          price={price}
          activePlan={activePlan}
        />
      </SubscriptionDescriptionText>
    </Stack>
  )
}
