import { Stack } from 'fitify-ui-onboarding/src/components'
import {
  ESubscriptionVariant,
  PlanInterval,
  Subscription,
} from 'fitify-ui-onboarding/src/types'
import { ReactNode, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'

import { StyledPlanList, StyledPricePlaceholder } from './Plan.Styled'
import { PlanDetail } from './PlanDetail'
import { PlanListItem } from './PlanListItem'

interface SubscriptionProps {
  subscription: Subscription | null
  button: ReactNode
  description?: ReactNode
  children?: ReactNode
  variant?: ESubscriptionVariant
}

export const PlanSubscription = ({
  button,
  children,
  description,
  subscription,
  variant,
}: SubscriptionProps) => {
  const hideDetail =
    variant === ESubscriptionVariant.GIFT ||
    variant === ESubscriptionVariant.PLAIN

  const filterMonthlyPlans = subscription?.plans?.find(
    (plan) => plan.interval === PlanInterval.MONTH
  )

  // futurePriceTotalValue is used as regular monthly price for discounted plans
  const regularMonthlyPrice =
    filterMonthlyPlans &&
    (filterMonthlyPlans?.futurePriceTotalValue ||
      filterMonthlyPlans?.priceTotalValue)

  const subscriptionPlans = useMemo(() => {
    const sortedPlans = subscription?.plans?.sort(
      (planA, planB) =>
        (planA.totalValue || planA.priceTotalValue || 0) -
        (planB.totalValue || planB.priceTotalValue || 0)
    )

    return sortedPlans ? (
      sortedPlans.map((plan) => {
        if (!plan.path) return null

        return (
          subscription && (
            <PlanListItem
              key={plan.path}
              variant={variant}
              plan={plan}
              onPlanChange={subscription.onPlanChange}
              isActive={subscription?.activePlan === plan.path}
              regularMonthlyPrice={regularMonthlyPrice}
            />
          )
        )
      })
    ) : (
      <StyledPricePlaceholder>
        <Skeleton
          containerClassName="price-skeleton"
          style={{ height: '100%', width: '100%' }}
        />
        <Skeleton
          containerClassName="price-skeleton"
          style={{ height: '100%', width: '100%' }}
        />
      </StyledPricePlaceholder>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription?.activePlan, subscription?.plans, variant])

  return (
    <Stack xs="1.5rem">
      <StyledPlanList $variant={variant}>{subscriptionPlans}</StyledPlanList>

      <Stack xs="0.75rem" align="center" style={{ marginBottom: '0.75rem' }}>
        {description}
        {button}

        {subscription && !hideDetail ? (
          <PlanDetail subscription={subscription} />
        ) : null}

        {children}
      </Stack>
    </Stack>
  )
}
