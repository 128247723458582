import { styled, keyframes, css } from 'styled-components'

const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `

const dashKeyframes = (offset: number) => keyframes`
    0% {
      stroke-dashoffset: ${offset};
    }
    50% {
      stroke-dashoffset: ${offset / 4};
      transform: rotate(90deg);
    }
    100% {
      stroke-dashoffset: ${offset};
      transform: rotate(370deg);
    }
  `

export const StyledSpinnerSvg = styled.svg<{
  $offset: number
  $duration: number
}>`
  animation: ${rotate} linear infinite;
  animation-duration: ${({ $duration }) => `${$duration}s`};

  circle {
    stroke-dasharray: ${({ $offset }) => $offset};
    stroke-dashoffset: 0;
    transform-origin: center;
    ${({ $offset, $duration }) => css`
      animation: ${dashKeyframes($offset)} ${$duration}s ease-in-out infinite;
    `}
  }
`
