import { createGlobalStyle } from 'styled-components'

import { colors, themeColors } from './colors'
import { coreElements } from './core'
import { themeVariables } from './variables'

export const GlobalStyles = createGlobalStyle`
        :root {
          /* VARIABLES */
          ${colors()}
          ${(props) => themeColors(props.theme)}
          ${(props) => themeVariables(props.theme)}

          /* CORE */
          ${(props) => coreElements(props.theme)}
        }
      `
