import { FC, HTMLAttributes } from 'react'

import { StyledChip, StyledChipProps } from './Chip.Styled'

export interface ChipProps
  extends HTMLAttributes<HTMLSpanElement>,
    StyledChipProps {}

export const Chip: FC<ChipProps> = ({ children, ...rest }) => {
  return <StyledChip {...rest}>{children}</StyledChip>
}
