import { RemoteContext } from 'fitify-ui/src/hooks/useRemoteConfig'
import { PlanInterval } from 'fitify-ui-onboarding/src/types'
import { useTranslation } from 'next-i18next'
import { useCallback, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { StyledBadge } from './Badge.styled'

export const Badge = ({
  planType,
  discountDuration,
  discountAmount,
  promoCode,
}: {
  planType: PlanInterval
  discountDuration?: number | null
  discountAmount: number | null
  promoCode: string | null
}) => {
  const { t } = useTranslation()
  const remoteConfig = useContext(RemoteContext)
  const theme = useContext(ThemeContext)

  const getBadgeConfig = useCallback(() => {
    // Gets defined badge type from RC or default
    let badge: string, placeholder: string

    if (theme?.hcV1) {
      badge =
        promoCode && discountDuration
          ? remoteConfig[`promo_${promoCode}_${discountDuration}_badge`]
          : remoteConfig[`payment_${planType}_badge`]
      placeholder = promoCode
        ? remoteConfig[
            `promo_${promoCode}_${discountDuration}_badge_placeholder`
          ]
        : remoteConfig[`payment_${planType}_badge_placeholder`]
    } else {
      placeholder = promoCode
        ? discountAmount
        : remoteConfig[`payment_${planType}_badge_placeholder`]

      badge = promoCode
        ? remoteConfig[`promo_${promoCode}_${planType}_badge`]
        : remoteConfig[`payment_${planType}_badge`]
    }

    return {
      badge,
      placeholder,
    }
  }, [
    discountAmount,
    discountDuration,
    planType,
    promoCode,
    remoteConfig,
    theme,
  ])

  function renderBadgeContent() {
    const { badge, placeholder } = getBadgeConfig()

    let badgeContent: string | undefined
    const badgePlaceholder =
      typeof placeholder === 'number' ? Math.round(placeholder) : placeholder

    switch (badge) {
      case 'x_off':
        badgeContent = t('pro_x_percent_off', { 0: badgePlaceholder })
        break

      case 'save_x':
        badgeContent = t('pro_save_x_percent', { 0: badgePlaceholder })
        break

      case 'best_value':
        badgeContent = t('pro_best_value')
        break

      case 'trial':
        badgeContent = discountDuration
          ? t('pro_free_trial_x_days', {
              0: Math.round(discountDuration),
              count: Math.round(discountDuration),
            })
          : undefined
        break

      default:
        badgeContent = undefined
        break
    }

    return badgeContent ? <StyledBadge>{badgeContent}</StyledBadge> : null
  }

  return renderBadgeContent()
}
