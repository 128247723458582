import { css } from 'styled-components'

import { Theme } from '../types/theme'
import { BREAKPOINTS } from '../utils/styled'

export const themeVariables = (theme: Theme) => {
  let themeVariables
  const base = css`
    /* CONTENT SIZE */
    --w-content-sm: 400px;
    --w-content: 480px;
    --w-content-max-width: 720px;
    --w-content-wide: 596px;
    --w-header: 960px;
    --h-header: 82px;
    --h-header-mobile: 66px;

    /* SPACING */
    --s-continue-button: 48px;
    @media ${BREAKPOINTS.MD.lte} {
      --s-continue-button: 32px;
    }

    /* INDEXES */
    --z-index-header: 999;
    --z-index-threshold-button: 998;
  `

  switch (true) {
    case theme.hcV1:
      themeVariables = css`
        /* SIZES */
        --w-content: 480px;

        /* TEXT */
        --f-heading: 'Graphik Wide Web';
        --f-text: 'Graphik LCG Web';
      `
      break

    case theme.digital:
      themeVariables = css`
        /* SIZES */
        --w-content: 480px;
        --w-plan-container: 375px;

        /* TEXT */
        --f-heading: 'Graphik LCG Web';
        --f-text: 'Graphik LCG Web';
      `
      break

    default:
      break
  }

  return css`
    ${base}
    ${themeVariables}
  `
}
