import { css } from 'styled-components'

import { type Theme } from '../types/theme'

export const coreElements = (theme: Theme) => {
  let themeBase
  const base = css`
    *,
    *:before,
    *:after {
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
    }

    img {
      pointer-events: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  `

  switch (true) {
    case theme.hcV1:
      themeBase = css`
        body {
          margin: 0;
          padding: 0;
          color: var(--c-gray700);
          background: var(--c-gray100);
          font-family: var(--f-text);
        }
      `
      break

    case theme.digital:
      themeBase = css`
        body {
          margin: 0;
          padding: 0;
          color: var(--c-gray700);
          background: var(--c-blue850);
          font-family: var(--f-text);
        }
      `
      break

    default:
      break
  }

  return css`
    ${base}
    ${themeBase}
  `
}
