import { type User } from 'firebase/auth'
import Icon, { IconTypes } from 'fitify-ui/src/Icon'
import {
  ProgressBar,
  StyledCircleButton,
} from 'fitify-ui-onboarding/src/components'
import { useStepProgress } from 'fitify-ui-onboarding/src/hooks'
import { ReactNode } from 'react'

import {
  StyledHeader,
  StyledHeaderContainer,
  StyledLogo,
  StyledLogoContainer,
} from './Header.Styled'
import HeaderUser from './HeaderUser'

type HeaderProps = {
  components?: {
    backButton?: IconTypes.Variants | null
    closeButton?: ReactNode
    logo?: {
      srcSet: string
      width: number
      height: number
      responsive?: {
        width: number
        height: number
      }
    }
  }
  data: {
    user: User | null
    promo: string | null
    logoutText: string
  }
  actions: {
    handleLogout: () => void
    handleOnPrevClick: () => void
  }
  options?: {
    isProgressHidden?: boolean
    isPrevHidden?: boolean
  }
}

const Header = ({ components, data, actions, options }: HeaderProps) => {
  const progress = useStepProgress()

  return (
    <StyledHeader>
      <StyledHeaderContainer>
        {components?.closeButton ? components.closeButton : null}

        <StyledLogoContainer>
          {!options?.isPrevHidden && (
            <StyledCircleButton
              onClick={actions.handleOnPrevClick}
              data-testid="header-back-button"
            >
              <Icon
                name={
                  components?.backButton ? components.backButton : 'arrow-left'
                }
                style={{ width: 20, height: 20 }}
              />
            </StyledCircleButton>
          )}

          {components?.logo ? (
            <StyledLogo {...components.logo} alt="Fitify Logo" />
          ) : null}
        </StyledLogoContainer>

        {data.user ? (
          <HeaderUser
            data={{
              user: data.user,
              logoutText: data.logoutText,
            }}
            onHandleLogout={actions.handleLogout}
          />
        ) : null}
      </StyledHeaderContainer>
      {!options?.isProgressHidden && <ProgressBar percentage={progress} />}
    </StyledHeader>
  )
}

export default Header
